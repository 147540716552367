import * as React from 'react';
import { routes } from './public-routes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const router = createBrowserRouter(routes());
export default function PublicApp() {
  return (
    <>
      <ToastContainer />
      <RouterProvider router={router} />
    </>

  );
}
