import { lazy } from 'react';
import Loadable from './Loadable';

const CommingSoonPage = Loadable(lazy(() => import('../pages/comming-soon')));


export const routes = () => {
  return [
  // Comming Soon Page
  {  path : "/" , element : <CommingSoonPage />}



    ];
};